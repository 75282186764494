@use "@wojo/ui/mixins" as *;

.banner {
    a {
        color: inherit;
    }
}

.card {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--g-spacing-sm);
    padding: var(--g-spacing-md);
    text-align: center;

    @include breakpoint-md {
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        padding: var(--g-spacing-lg) var(--g-spacing-xl);
    }
}

.card-left {
    display: flex;
    gap: var(--g-spacing-sm);
    flex-direction: column;

    @include breakpoint-md {
        align-items: center;
        flex-direction: row;
        gap: var(--g-spacing-md);
        text-align: left;
    }
}

.avatar-wrapper {
    height: 80px;
    margin: auto;
    width: 80px;

    & > span {
        padding: 0;
    }
}

.name-with-loyalty-level {
    margin-bottom: var(--g-spacing-sm);
}

.loyalty-level {
    display: flex;
    gap: var(--g-spacing-sm);
    justify-content: center;

    @include breakpoint-md {
        justify-content: flex-start;
    }
}
